<template>
    <li class="menu-link" :class="subMenuClassName">
        <router-link :to="model.url" v-if="!subMenu && !isExternal">{{ model.text }}</router-link>
        <a :href="model.url" v-if="!subMenu && isExternal" target="_blank">{{ model.text }}</a>
        <button v-if="subMenu" @click="toggleOpen">{{ model.text }}</button>
        <ul class="sub-menu" v-if="model.links && model.links.length > 0">
            <nav-menu-item :model="lnk" v-for="lnk in model.links" :key="lnk.url"></nav-menu-item>
        </ul>
    </li>
</template>

<script lang="js">
    export default {
        name: "nav-menu-item",
        props: {
            model: Object
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            subMenu() {
                return this.model.links && this.model.links.length > 0;
            },
            subMenuClassName() {
                return this.model.text.replace(/&/g, '').replace(/\s\s/g, '-').replace(/\s/g, '-').replace(/'/g, '').replace('?', '').toLowerCase() + (this.open ? " sub-menu-open" : "");
            },
            isExternal() {
                return this.model.url.startsWith("http");
            }
        },
        methods: {
            toggleOpen() {
                this.open = !this.open;
            }
        },
        watch: {
            '$route'() {
                if (this.open) this.toggleOpen();
            }
        }
    };
</script>

<style>
    li.menu-link {
        margin: 2px 0 0 0;
        display: block;
    }

    li.menu-link a, li.menu-link button {
        display: block;
        background-color: var(--green5);
        color: #fff;
        padding: 0.8125rem 1.5rem;
        text-decoration: none;
        font-size: var(--font-size-2);
        font-weight: 500;
        line-height: 1rem;
        width: 100%;
        -moz-transition: background-color .2s linear;
        -o-transition: background-color .2s linear;
        -webkit-transition: background-color .2s linear;
        transition: background-color .2s linear;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    li.menu-link button {
        padding-right: 2.5rem;
    }

            li.menu-link button {
                border: none;
                cursor: pointer;
                text-align: left;
                position: relative;
            }
                li.menu-link button:after {
                    content: url(../../assets/icons/expand.svg);
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    position: absolute;
                    top: calc(50% - 0.5rem);
                    right: 1rem;
                    -moz-transition: all .2s linear;
                    -o-transition: all .2s linear;
                    -webkit-transition: all .2s linear;
                    transition: all .2s linear;
                }

        li.menu-link ul.sub-menu {
            max-height: 0;
            overflow: hidden;
            margin: 0;
            padding: 0;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            list-style-type: none;
            -moz-transition: max-height .4s ease-in-out;
            -o-transition: max-height .4s ease-in-out;
            -webkit-transition: max-height .4s ease-in-out;
            transition: max-height .4s ease-in-out;
        }

            li.menu-link ul.sub-menu li {
                text-transform: uppercase;
            }

    /* Colours */
    ul.sub-menu li.menu-link a, ul.sub-menu li.menu-link button {
        background-color: var(--green5);
    }
    li.things-to-do li.menu-link a, li.things-to-do li.menu-link button {
        background-color: var(--blue3);
    }
    li.things-to-do li.menu-link:hover > a, li.things-to-do li.menu-link:hover > button {
        background-color: var(--blue2);
    }
    li.plan-your-visit li.menu-link a, li.plan-your-visit li.menu-link button {
        background-color: var(--green3);
    }
    li.plan-your-visit li.menu-link:hover > a, li.plan-your-visit li.menu-link:hover > button {
        background-color: var(--green1);
    }
    ul li.menu-link:hover > a, ul li.menu-link:hover > button {
        background-color: var(--red1);
    }

    /* Icons */
    li.menu-link.things-to-do li.menu-link a {
        background-position: center left 1rem;
        background-repeat: no-repeat;
        background-size: 1.5rem auto;
        padding-left: 3.25rem;
    }
    li.menu-link.dinosaurs a, li.menu-link.jurassic-wales a {
        background-image: url(../../assets/icons/dinosaur.svg);
    }
    li.menu-link.step-through-time a {
        background-image: url(../../assets/icons/time.svg);
    }
    li.menu-link.step-into-the-wild a {
        background-image: url(../../assets/icons/wild.svg);
    }
    li.menu-link.castle-gardens a {
        background-image: url(../../assets/icons/gardens.svg);
    }
    li.menu-link.interactive-story-trail a, li.menu-link.welsh-folklore-trail a {
        background-image: url(../../assets/icons/story.svg);
    }
    li.menu-link.eating-drinking a {
        background-image: url(../../assets/icons/eating.svg);
    }
    li.menu-link.disc-golf a {
        background-image: url(../../assets/icons/disc.svg);
    }
    li.menu-link.bespoke-catering a {
        background-image: url(../../assets/icons/catering.svg);
    }
    li.menu-link.medieval-farm a, li.menu-link.medieval-village a {
        background-image: url(../../assets/icons/medieval.svg);
    }
    li.menu-link.true-aim-activities a {
        background-image: url(../../assets/icons/trueaim.svg);
    }

    li.menu-link.things-to-do li.menu-link.whats-here a {
        background: var(--red1);
        padding-left: 1.5rem;
        text-transform: none;
    }
        li.menu-link.things-to-do li.menu-link.whats-here a:hover {
            background: var(--red2);
        }

    li.menu-link.visitor-info li.menu-link.book-your-day-ticket a {
        background: var(--red1);
        text-transform: none;
    }

    /*Open*/
    li.sub-menu-open ul.sub-menu {
        max-height: 30em;
    }
    ul li.sub-menu-open > a, ul li.sub-menu-open > button {
        background-color: var(--red1);
    }
        ul li.sub-menu-open > button:after {
            transform: rotate(180deg);
        }

    @media screen and (min-width: 925px) {
        ul.root-menu > li.menu-link {
            margin-top: 0;
        }

        li.menu-link {
            position: relative;
            margin-top: 1px;
            -ms-flex: 0 0 10%;
            -webkit-flex: 0 0 10%;
            flex: 0 0 10%;
        }

            li.menu-link a, li.menu-link button {
                background-color: transparent;
                text-align: center;
                white-space: nowrap;
                font-size: var(--font-size-1);
            }

            li.menu-link ul.sub-menu {
                position: absolute;
                top: 100%;
                width: auto;
                min-width: 100%;
                max-height: 0;
                overflow: hidden;
                background-color: #fff;
            }

                li.menu-link ul.sub-menu li.menu-link a, li.menu-link ul.sub-menu li.menu-link button {
                    text-align: left;
                }

        /*Open*/
            li.sub-menu-open ul.sub-menu {
                max-height: 30em;
            }

        ul li.sub-menu-open > button:after {
            transform: rotate(180deg);
        }

    }

    @media (hover: hover) and (pointer: fine) and (min-width: 925px) {
        /* Only use hover sub menu open on desktops with mouse */
        li.menu-link:hover ul.sub-menu {
            max-height: 30em;
        }

        ul li:hover > button:after {
            transform: rotate(180deg);
        }
    }


    @media screen and (min-width: 1000px) {
        li.menu-link a, li.menu-link button {
            font-size: var(--font-size-2);
        }
    }

</style>
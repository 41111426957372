<template>
    <component :is="getComponent" :content="content"></component>
</template>

<script lang="js">
    import { defineAsyncComponent } from 'vue'

    const contentComponents = {
        "tags": defineAsyncComponent(() =>
            import('./ContentCaddy.vue')
        ),
        "snippets": defineAsyncComponent(() =>
            import('./ContentSnippet.vue')
        ),
        "links": defineAsyncComponent(() =>
            import('./ContentLink.vue')
        ),
        "locations": defineAsyncComponent(() =>
            import('./ContentLocation.vue')
        )
    }

    export default {
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        computed: {
            getComponent() {
                return contentComponents[this.content.taxonomy];
            }
        }
    };
</script>

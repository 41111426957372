<template>
    <nav role="navigation" class="mainnav" v-if="navLinks && navLinks.length > 0" :class="{ 'menu-open': navOpen }">
        <ul class="root-menu container flex">
            <nav-menu-item :model="lnk" v-for="lnk in navLinks" :key="lnk.url"></nav-menu-item>
        </ul>
        <slot></slot>
    </nav>
</template>

<script lang="js">
    import { mapGetters } from 'vuex'

    import NavMenuItem from './NavMenuItem.vue'
    export default {
        name: "nav-menu",
        components: { NavMenuItem },
        props: {
            navOpen: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            ...mapGetters({ navLinks: 'nav/navLinks' })
        }
    };
</script>

<style>
    nav.mainnav {
        position: absolute;
        top: var(--header-height);
        width: 100%;
        height: 0;
        z-index: 3;
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        background-color: #fffffff2;
        opacity: 0;
        overflow-y: auto;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-transition: opacity .2s linear .4s, height .2s linear .4s;
        -o-transition: opacity .2s linear .4s, height .2s linear .4s;
        -webkit-transition: opacity .2s linear .4s, height .2s linear .4s;
        transition: opacity .2s linear .4s, height .2s linear .4s;
    }

        nav.mainnav ul.root-menu {
            max-height: 0;
            overflow: hidden;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: var(--black);
            list-style-type: none;
            -moz-transition: max-height .4s ease-in-out;
            -o-transition: max-height .4s ease-in-out;
            -webkit-transition: max-height .4s ease-in-out;
            transition: max-height .4s ease-in-out;
        }

    /*Open*/
        nav.mainnav.menu-open {
            opacity: 1;
            height: calc(100vh - var(--header-height));
            -moz-transition: opacity .2s linear, height .1s linear;
            -o-transition: opacity .2s linear, height .1s linear;
            -webkit-transition: opacity .2s linear, height .1s linear;
            transition: opacity .2s linear, height .1s linear;
        }

            nav.mainnav.menu-open ul.root-menu {
                max-height: 75em;
                -moz-transition: max-height .4s ease-in-out .2s;
                -o-transition: max-height .4s ease-in-out .2s;
                -webkit-transition: max-height .4s ease-in-out .2s;
                transition: max-height .4s ease-in-out .2s;
            }

    @media screen and (min-width: 800px) {
        nav.mainnav {
            top: var(--header-height-lge);
        }
    }
    
    @media screen and (min-width: 925px) {

        nav.mainnav {
            position: relative;
            top: auto;
            height: auto;
            background-color: var(--black34);
            opacity: 1;
            overflow-y: visible;
        }

            nav.mainnav ul.root-menu, nav.mainnav.menu-open {
                margin-bottom: 0;
                height: 100%;
                max-height: 100%;
                overflow: visible;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -ms-flex-wrap: nowrap;
                -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
            }

                nav.mainnav.menu-open ul.root-menu {
                    max-height: 100%;
                }

    }

</style>
<template>
    <button type="button" class="mainnav" v-on:click="toggleNav" :class="{ open: open }"><span>Menu</span></button>
</template>

<script lang="js">
    import { bsdom } from '../../tools.js'

    export default {
        name: "nav-button",
        emits: ['menu-toggle'],
        data() {
            return {
                open: false
            }
        },
        methods: {
            toggleNav: function () {
                // toggle open
                this.open = !this.open;
                // emit value
                this.$emit('menu-toggle', this.open);
                // Set body class
                bsdom.get(document.body).csstoggle("menu-open");
            }
        },
        watch: {
            '$route'() {
                if(this.open) this.toggleNav();
            }
        }
    };
</script>

<style>
    button.mainnav {
        font-size: 1em;
        background-color: transparent;
        border: 0;
        color: #fff;
        text-indent: -99px;
        overflow: hidden;
        opacity: 1;
        height: 32px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        -ms-flex: 0 0 32px;
        -webkit-flex: 0 0 32px;
        flex: 0 0 32px;
        -moz-transition: opacity linear .2s;
        -o-transition: opacity linear .2s;
        -webkit-transition: opacity linear .2s;
        transition: opacity linear .2s;
    }

        /*Closed*/
        button.mainnav span {
            display: block;
            width: 100%;
            height: 3px;
            background-color: #fff;
            font-size: 0;
            border-radius: 2px;
            -moz-transition: background-color .2s ease-out .6s;
            -o-transition: background-color .2s ease-out .6s;
            -webkit-transition: background-color .2s ease-out .6s;
            transition: background-color .2s ease-out .6s;
        }
            button.mainnav span:before {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                background-color: #fff;
                position: relative;
                top: -10px;
                border-radius: 2px;
                -moz-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -o-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -webkit-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
            }
            button.mainnav span:after {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                background-color: #fff;
                position: relative;
                top: 7px;
                border-radius: 2px;
                -moz-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -o-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -webkit-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
            }

        /*Open*/
        button.mainnav.open span {
            background-color: var(--black);
            -moz-transition: background-color .2s ease-out;
            -o-transition: background-color .2s ease-out;
            -webkit-transition: background-color .2s ease-out;
            transition: background-color .2s ease-out;
        }
            button.mainnav.open span:before {
                transform: rotate(45deg);
                top: 0;
                -moz-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -o-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -webkit-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
            }
            button.mainnav.open span:after {
                transform: rotate(-45deg);
                top: -3px;
                -moz-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -o-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -webkit-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
            }

    @media screen and (min-width: 350px) {
        button.mainnav {
            height: 43px;
            -ms-flex: 0 0 43px;
            -webkit-flex: 0 0 43px;
            flex: 0 0 43px;
        }
    }

    @media screen and (min-width: 925px) {
        button.mainnav {
            display: none;
        }
    }

</style>
<template>
    <div id="header-outer" class="header-outer">
        <header id="site-header" class="site-header">
            <nav class="quick-menu">
                <ul class="container flex">
                    <li class="flex"><router-link to="/visitor-info/directions-opening-times" class="flex"><span>How to find us</span></router-link></li>
                    <li class="flex"><router-link to="/visitor-info/directions-opening-times#currentopeningtimes" class="flex"><span>Opening times</span></router-link></li>
                    <li class="flex"><a href="https://fonmoncastle.digitickets.co.uk/" target="_blank" class="button">Book tickets</a></li>
                </ul>
            </nav>
            <div class="container flex">
                <LogoButton>
                    <p>Barry, Wales CF62 3ZN</p>
                </LogoButton>
                <content-caddy-header :content="headerHighlight" />
                <nav-button @menu-toggle="toggleNav" />
            </div>
            <nav-menu :navOpen="navOpen">
                <content-caddy-header :content="headerHighlight" />
            </nav-menu>
        </header>
    </div>
</template>

<script lang="js">
    import { mapGetters } from 'vuex'
    import NavButton from '../nav/NavButton.vue'
    import NavMenu from '../nav/NavMenu.vue'
    import LogoButton from './LogoButton.vue'
    import ContentCaddyHeader from '../content/header/ContentCaddy.vue'

    export default {
        name: "the-header",
        mounted() {
            // Get the main links
            this.$store.dispatch('content/getCaddy', { id: 3718, name: "headerCaddy" });
        },
        components: {
            NavButton,
            NavMenu,
            LogoButton,
            ContentCaddyHeader
        },
        data() {
            return {
                navOpen: false
            }
        },
        computed: {
            ...mapGetters({ caddyByName: 'content/caddyByName' }),
            headerHighlight() {
                if (!this.caddyByName('headerCaddy')) return null;
                // This extracts the first content item from the footer caddy. This will be the FAQs
                const headHighlight = this.caddyByName('headerCaddy').content.shift();
                return headHighlight;
            }
        },
        methods: {
            toggleNav: function (v) {
                this.navOpen = v;
            }
        }
    };
</script>

<style>
    :root {
        /*Header height */
        --header-height: 9em;
        --header-height-lge: 11.25em;
    }

    .site-header {
        background: var(--green5);
        background-size: cover;
        z-index: 3;
        width: 100%;
        height: var(--header-height);
        position: relative;
    }

        .site-header div.flex {
            align-items: center;
            padding: 0.8125rem 1.5rem 1.0625rem 1.5rem;
        }

        /*Quick menu */
        .site-header nav.quick-menu ul.flex {
            align-content: stretch;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            list-style-type: none;
        }
        .site-header nav.quick-menu li.flex {
            flex-basis: 33.3333%;
            text-align: center;
            align-content: stretch;
            margin-bottom: 0;
        }
        .site-header nav.quick-menu li a {
            color: #fff;
            text-decoration: none;
            font-size: var(--font-size-1);
            line-height: 0.875rem;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -moz-transition: background-color .2s linear;
            -o-transition: background-color .2s linear;
            -webkit-transition: background-color .2s linear;
            transition: background-color .2s linear;
        }
            .site-header nav.quick-menu li a:hover {
                background-color: var(--black34);
            }

            .site-header nav.quick-menu a span {
                vertical-align: middle;
            }
            .site-header nav.quick-menu a.flex {
                align-items: center;
            }
            .site-header nav.quick-menu a.button {
                line-height: 1rem;
                background: var(--red1) url(../../assets/icons/external.svg) no-repeat top 0.1em right 0.1em;
                padding: 0.75rem;
                background-size: 1em;
                border-radius: 0;
            }
                .site-header nav.quick-menu a.button:hover {
                    background-color: var(--red2);
                }

    @media screen and (max-width: 800px) {
        .site-header:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent linear-gradient(90deg, #000000AF 0%, #373737 27%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
            mix-blend-mode: multiply;
            opacity: 0.4;
            z-index: -1;
        }
    }

    @media screen and (min-width: 800px) {
        .site-header {
            height: auto;
        }
            /*Quick menu */
            .site-header nav.quick-menu {
                background-color: var(--black34);
            }
                .site-header nav.quick-menu ul.flex {
                    justify-content: flex-end
                }

                .site-header nav.quick-menu li.flex {
                    flex-basis: auto;
                }

                .site-header nav.quick-menu a, .site-header nav.quick-menu a.button {
                    padding-left: 4rem;
                    padding-right: 4rem;
                }

    }
</style>
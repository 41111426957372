// Content Module - For getting content caddies
import { fetchData } from '../../tools.js'
// Initial state
const state = () => ({
    content: {},
    appLoaded: false
})

// getters
const getters = {
    destinationMeta: (state) => {
        if (!state.content.title) return null;
        const meta = {
            title: state.content.title,
            description: state.content.description,
            socialDescription: state.content.smDescription,
            socialTitle: state.content.smTitle,

            siteName: state.content.site.name,
            siteUrl: state.content.site.url,
            siteSsl: state.content.site.ssl,
            siteSocialImage: state.content.site.defaultSMImage,
            siteSocialImageAlt: state.content.site.defaultSMImageAlt,
            siteLocale: state.content.site.locale,
            siteFBName: state.content.site.facebookName,
            siteTwitterName: state.content.site.twitterName,
            siteTwitterCardType: state.content.site.twitterCardType,
            siteDefaultBannerImage: state.content.site.defaultBannerImage
        }
        // Social image
        if (state.content.smImage) {
            meta.socialImage = state.content.smImage;
            if (state.content.smImageAlt) meta.socialImageAlt = state.content.smImageAlt
        }
        else if (state.content.file.length > 0) {
            meta.socialImage = state.content.file[0].url;
            meta.socialImageAlt = state.content.file[0].name;
        }
        else if (state.content.site.defaultSMImage) {
            meta.socialImage = state.content.site.defaultSMImage;
            meta.socialImageAlt = state.content.site.defaultSMImageAlt;
        }
        return meta;
    },
    destinationContent(state) {
        if (state.content === undefined) return undefined;
        return state.content;
    },
    destinationTemplate(state) {
        if (state.content === undefined) return undefined;
        return state.content.template;
    }
}

// actions
const actions = {
    async getContent({ commit }, params) {
        if (window.pageData && window.pageData.path == params.url) commit('setContent', { data: window.pageData.data }); // Only need to run this on entry to the site
        const content = await fetchData('/destinationcontent', params.url, null, 2)
            .then(function (response) {
                return response;
            });

        commit('setContent', { data: content });
    }
}

// mutations
const mutations = {
    setContent(state, params) {
        // Set data
        state.content = params.data;
        state.appLoaded = true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
    <the-header />
    <router-view />
    <the-footer />
</template>

<script>
    import TheHeader from './components/ui/TheHeader.vue'
    import TheFooter from './components/ui/TheFooter.vue'
    //import { event } from 'vue-gtag'

export default {
    name: 'App',
    mounted() {
        // Intercept links to use vue router on internal links and add GA events to external and files (downloads and external clicks are handled by GA enhanced events so we haven't added them here. Left in for info)
        window.addEventListener('click', event => {
            // ensure we use the link, in case the click has been received by a subelement
            let { target } = event
            while (target && target.tagName !== 'A') target = target.parentNode
            // handle only links that do not reference external resources
            if (target && target.matches("a:not([href*='://']):not([href*='mailto:']):not([href*='tel:']):not([href*='/_resources/'])") && target.href) {
                // some sanity checks taken from vue-router:
                // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
                // don't handle with control keys
                if (metaKey || altKey || ctrlKey || shiftKey) return
                // don't handle when preventDefault called
                if (defaultPrevented) return
                // don't handle right clicks
                if (button !== undefined && button !== 0) return
                // don't handle if `target="_blank"`
                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target')
                    if (/\b_blank\b/i.test(linkTarget)) return
                }
                // don't handle same page links/anchors
                const url = new URL(target.href);
                if (window.location.pathname !== url.pathname && event.preventDefault) {
                    event.preventDefault();
                    this.$router.push(url.pathname + url.search + url.hash);
                }
            }
        //    else if (target && target.matches("a[href*='://'], a[href*='mailto:'], a[href*='tel:'], a[href*='/_resources/']")) {
        //        console.log(target);
        //        console.log(this.$route)

        //        // Log a GA Event as:
        //        // 
        //        event('filter', { 'event_category': 'work', 'event_label': 'type', 'value': this.filterType });

        //    }
        //    console.log(target && target.matches("a[href*='://'], a[href*='mailto:'], a[href*='tel:'], a[href*='/_resources/']"))
        })
        // Get the main links
        this.$store.dispatch('nav/getNav', "all");
    },
    components: {
        TheHeader,
        TheFooter
    }
}
</script>

<style>
/*#region Variables 
--------------------------  */

    :root {
        /*Colours*/
        /*Blues*/
        --blue1: #3D4E5B;
        --blue2: #4E616E;
        --blue3: #afbfd9;
        --blue4: #E5EEFF;
        --blue334: #73A9CE57;
        /*Greens*/
        --green1: #657939;
        --green2: #78AA48;
        --green3: #4a8e77;
        --green4: #D4FAC8;
        --green5: #263437;
        --green534: #26343757;
        /*Browns*/
        --brown1: #77746D;
        --brown2: #C4B8A2;
        --brown3: #E0DDD0;
        --brown4: #F7F1E7;
        /*Yellows*/
        --yellow1: #FDAF01;
        --yellow2: #F4CE67;
        --yellow3: #FFE867;
        --yellow4: #FFFAE2;
        /*Reds*/
        --red1: #a83636;
        --red2: #C40D0D;
        --red170: #C40D0DB3;
        /*Blacks*/
        --black1: #414141;
        --black2: #797979;
        --black3: #F2F2F2;
        --black4: #B2B2B2;
        --black34: #00000057;
        --black50: #00000080;
        --black75: #000000bb;
        /*svg colours*/
        --svg-blue1: url(#blue1);
        --svg-blue2: url(#blue2);
        --svg-brown1: url(#brown1);
        --svg-brown2: url(#brown2);
        --svg-green1: url(#green1);
        --svg-green2: url(#green2);
        /*Fonts */
        --font-heading: fertigo-pro, serif;
        --font-text: museo-sans, sans-serif;
        --font-size-0: 0.75rem;
        --font-size-1: 0.875rem;
        --font-size-2: 1rem;
        --font-size-3: 1.125rem;
        --font-size-4: 1.375rem;
        --font-size-5: 1.5rem;
        --font-size-6: 1.75rem;
        --font-size-7: 2.125rem;
    }

/*#endregion */

/*#region Classes 
--------------------------  */

    /* Container */
    .container {
        max-width: 75em;
        margin-left: auto;
        margin-right: auto;
    }

    .centre {
        text-align: center;
    }

    /* Flex */
    .flex {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

        .flex.column {
            -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
        }

        .flex > * {
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
        }

    .button {
        text-align: center;
        background-color: var(--red1);
        color: #fff;
        display: inline-block;
        border-radius: 0.5em;
        padding: 0.5em 0.75em;
        border-radius: 1em;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-transition: background-color .2s linear;
        -o-transition: background-color .2s linear;
        -webkit-transition: background-color .2s linear;
        transition: background-color .2s linear;
    }

        .button:hover {
            text-decoration: none;
            background-color: var(--red2);
        }


    .fade-enter-active {
        transition: opacity 0.5s ease;
        transition-delay: 0.2s;
    }
    .fade-leave-active {
        transition: opacity 0.5s ease;
        transition-delay: 0.1s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

/*#endregion */

/*#region Typography 
--------------------------  */

    h1, h2, h3, h4, h5 {
        font-family: var(--font-text);
        font-weight: 700;
        line-height: 1.2em;
        margin: 0 0 1rem 0;
    }

    h1 {
        font-size: var(--font-size-3);
    }

    h2 {
        font-size: var(--font-size-2);
    }

    h3, h4, h5 {
        font-size: var(--font-size-1);
    }

    header h1, header h2, header h3, header h4, header h5, footer h1, footer h2, footer h3, footer h4, footer h5 {
        font-family: var(--font-heading);
        font-weight: 400;
    }

    p {
        font-size: var(--font-size-1);
        line-height: 1.25em;
        margin: 0 0 1em 0;
    }
    li, dt, dd {
        font-size: var(--font-size-1);
        line-height: 1.25em;
        margin: 0 0 1em 0;
    }
    li li {
        font-size: 1em;
    }

    strong {
        font-weight: 700;
    }

    button {
        font-family: var(--font-text);
        font-weight: 500;
        margin: 0;
    }

    body {
        font-family: var(--font-text);
        font-weight: 500;
    }

    img {
        max-width: 100%;
    }

    picture img {
        display: block;
    }

    table {
        padding: 0;
        margin: 0;
    }
    table thead {
        font-weight: 700;
    }
    table td {
        padding: 0.5em;
        border-bottom: solid 1px var(--black3);
    }

    @media screen and (max-width: 800px) {
        table td {
            display: block;
        }
        table tr td:last-child {
            margin-bottom: 2em;
        }
    }

    @media screen and (min-width: 800px) {
        h1 {
            font-size: var(--font-size-5);
        }

        h2 {
            font-size: var(--font-size-4);
        }

        h3, h4, h5 {
            font-size: var(--font-size-3);
        }
        p {
            font-size: var(--font-size-3);
        }

        li, dt, dd {
            font-size: var(--font-size-3);
        }
   }
/*#endregion */

/*#region  Main Structure
-------------------------------------  */

    html {
        font-size: 1em;
        scroll-behavior: smooth;
        min-width: 20em;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
        display: block;
    }

    picture {
        display: inline-block;
    }

    body {
        position: relative;
        text-align: left;
        font-size: 1em;
        background-color: #fff;
        color: var(--black1);
        width: 100%;
        padding: 0;
        margin: 0;
        min-width: 20em;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    body.menu-open {
        overflow: hidden;
    }

    main {
        position: relative;
        z-index: 2;
        background-color: #fff;
        margin: 0.25em 0;
    }

        main .inner-container {
            padding-top: 1em;
            padding-bottom: 1em;
        }

        main *:last-child {
            margin-bottom: 0;
        }

        main h1, main h2, main h3, main h4, main h5 {
            text-transform: uppercase;
        }

        main a {
            color: var(--green2);
            text-decoration: none;
            -moz-transition: color .2s linear;
            -o-transition: color .2s linear;
            -webkit-transition: color .2s linear;
            transition: color .2s linear;
        }

            main a:hover {
                text-decoration: underline;
            }

        main ul {
            padding: 0;
            margin: 1.5em 0;
            list-style-type: none;
            border-top: solid 1px var(--black4);
        }

            main ul li {
                padding: 0.5em;
                margin: 0;
                border-bottom: solid 1px var(--black4);
            }

    @media screen and (min-height: 600px) {
        main .inner-container {
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }

    @media screen and (min-height: 800px) {
        main .inner-container {
            padding-top: 3.25em;
            padding-bottom: 3.25em;
        }
    }

/*#endregion */

</style>

<template>
    <Transition name="fade" appear>
        <component :is="containerTag" class="content-caddy" v-if="content" :class="className">
            <h1 v-if="content.showHeading" @click="toggleOpen">{{ content.name }}</h1>
            <content-component-loader :content="item" v-for="item in content.content" :key="item.hierarchyId" />
        </component>
    </Transition>
</template>

<script lang="js">
    import ContentComponentLoader from './ContentComponentLoader.vue'

    export default {
        components: { ContentComponentLoader },
        props: {
            content: {
                type: Object,
                required: true
            },
            containerTag: {
                type: String,
                required: false,
                default: "section"
            }
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            className() {
                return this.content.name.replace(/&/g, '').replace(/\s\s/g, '-').replace(/\s/g, '-').toLowerCase() + (this.content.classNames ? ` ${this.content.classNames}` : "") + (this.open ? " open" : "");
            }
        },
        methods: {
            toggleOpen() {
                this.open = !this.open;
                console.log(this.open)
            }
        }
    };
</script>

<style>
    .site-header .content-caddy.header-highlight {
        display: none;
    }
    .site-header .mainnav .content-caddy.header-highlight {
        display: block;
        padding: 1em;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    @media screen and (min-width: 800px) {
        .site-header .mainnav .content-caddy.header-highlight {
            display: none;
        }
        .site-header .content-caddy.header-highlight {
            flex-basis: auto;
            display: block;
        }
    }

    @media screen and (min-width: 1100px) {
        .site-header .content-caddy.header-highlight {
            flex-basis: auto;
        }
    }
</style>
// Nav Module - Holds nav related data
import { fetchData } from '../../tools.js'
import navData from '../default-data/nav.json'
// Initial state
const state = () => ({
    nav: navData
})

// getters
const getters = {
    navLinks: (state) => {
        if (state.nav.mainLinks === undefined) return undefined;
        return state.nav.mainLinks;
    }

}

// actions
const actions = {
    async getNav({ commit }, name) {

        const navData = await fetchData('/navlist', name, null, 2)
            .then(function (response) {
                return response;
            });

        commit('setNav', navData);
    }
}

// mutations
const mutations = {
    setNav(state, navData) {
        // Set data
        state.nav.mainLinks = navData;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
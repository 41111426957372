import { createStore } from 'vuex'
import nav from './modules/nav.js'
import content from './modules/content.js'
import destination from './modules/destination.js'
import event from './modules/event.js'
import faq from './modules/faq.js'

// Vuex data store
// Expose a new store instance.
export default createStore({
    modules: {
        nav,
        content,
        destination,
        event,
        faq
    }
});

<template>
    <router-link to="/" class="logo-button flex">
        <picture class="logo-image">
            <source type="image/svg+xml" srcset="../../assets/logo.svg" />
            <img srcset="../../assets/logo.png 1x,../../assets/logo@2x.png 2x" src="../../assets/logo.png" alt="Fonmon Castle Crest" />
        </picture>
    </router-link>
</template>

<script lang="js">
    export default {
        name: "logo-button"
    };
</script>

<style>
    a.logo-button {
        color: #fff;
        text-decoration: none;
        -ms-flex: 0 0 auto;
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        justify-content: flex-start;
        align-items: center;
    }

        a.logo-button picture.logo-image {
            flex-basis: 3.75em;
        }
                a.logo-button picture.logo-image img {
                    display: block;
                }

    @media screen and (min-width: 800px) {
        a.logo-button, a.logo-button picture.logo-image {
            flex-basis: 5.6875em;
        }
    }
</style>
// Content Module - For getting content caddies
import { fetchData } from '../../tools.js'
import faqData from '../default-data/faqs.json'
// Initial state
const state = () => ({
    faqs: faqData
})

// getters
const getters = {
    faqList(state) {
        if (state.faqs === undefined) return undefined;
        return state.faqs;
    }
}

// actions
const actions = {
    async getFAQs({ commit }, params) {
        const items = await fetchData('/knowledgebase', params, null, 2)
            .then(function (response) {
                return response;
            });

        commit('setFAQs', { data: items });
    }
}

// mutations
const mutations = {
    setFAQs(state, params) {
        // Set data
        state.faqs = params.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
// Content Module - For getting content caddies
import { fetchData } from '../../tools.js'
// Initial state
const state = () => ({
    content: {
    }
})

// getters
const getters = {
    caddyByName(state) {
        return (name) => {
            if (state.content[name] === undefined) return undefined;
            return state.content[name];
        }
    }

}

// actions
const actions = {
    async getCaddy({ commit }, params) {
        const caddyData = await fetchData('/tagcontent', params.id, null, 2)
            .then(function (response) {
                return response;
            });

        commit('setCaddy', { data: caddyData, name: params.name });
    }
}

// mutations
const mutations = {
    setCaddy(state, params) {
        // Set data
        state.content[params.name] = params.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
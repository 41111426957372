<template>
    <component :is="containerTag" class="content-caddy" v-if="content" :class="className" >
        <h1 v-if="content.showHeading" @click="toggleOpen">{{ content.name }}</h1>
        <content-component-loader :content="item" v-for="item in content.content" :key="item.hierarchyId" />
    </component>
</template>

<script lang="js">
    import ContentComponentLoader from './ContentComponentLoader.vue'

    export default {
        components: { ContentComponentLoader },
        props: {
            content: {
                type: Object,
                required: true
            },
            containerTag: {
                type: String,
                required: false,
                default: "section"
            }
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            className() {
                return this.content.name.replace(/&/g, '').replace(/\s\s/g, '-').replace(/\s/g, '-').toLowerCase() + (this.content.classNames ? ` ${this.content.classNames}` : "") + (this.open ? " open" : "");
            }
        },
        methods: {
            toggleOpen() {
                this.open = !this.open;
                console.log(this.open)
            }
        }
    };
</script>

<style>

    .site-footer .footer .content-caddy {
        background-color: var(--blue334);
        color: #fff;
        font-family: var(--font-text);
        text-align: left;
        max-height: 2.875rem;
        overflow: hidden;
        margin-bottom: 0.75rem;
        -moz-transition: max-height .2s linear;
        -o-transition: max-height .2s linear;
        -webkit-transition: max-height .2s linear;
        transition: max-height .2s linear;
    }

        .site-footer .footer .content-caddy.open {
            max-height: 50rem;
        }

        .site-footer .footer .content-caddy > * {
            padding: 0.625rem 1.25rem;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

    .site-footer .grid .content-caddy h1 + *xxx {
        padding-top: 0;
    }

    .site-footer .content-caddy h1 {
        font-family: var(--font-text);
        font-size: var(--font-size-4);
        padding: 0.625rem 1.25rem;
        margin-bottom: 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        cursor: pointer;
        width: 100%;
    }

        .site-footer .content-caddy h1:after {
            content: url(../../../assets/icons/expand.svg);
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            top: calc(50% - 0.75rem);
            right: 1.5rem;
            -moz-transition: all .2s linear;
            -o-transition: all .2s linear;
            -webkit-transition: all .2s linear;
            transition: all .2s linear;
        }

    .site-footer .content-caddy.open h1:after {
        transform: rotate(180deg);
    }

    .site-footer .content-caddy p {
        font-size: 0.875em;
    }

        .site-footer .content-caddy p a {
            color: var(--green2);
            text-decoration: none;
        }

            .site-footer .content-caddy p a:hover {
                text-decoration: underline;
            }


    @media screen and (min-width: 800px) {
        .site-footer .content-caddy h1 {
            font-size: var(--font-size-6);
            cursor: default;
        }

            .site-footer .content-caddy h1:after {
                display: none;
            }

        .site-footer .grid > .content-caddy, .site-footer .grid > .content-caddy.open {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            max-height: none;
            margin-bottom: 0;
        }
    }

</style>
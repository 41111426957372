// Content Module - For getting content caddies
import { fetchData } from '../../tools.js'
// Initial state
const state = () => ({
    events: [],
    event: {}
})

// getters
const getters = {
    eventList(state) {
        if (state.events === undefined) return undefined;
        return state.events;
    },
    eventData(state) {
        if (state.event === undefined) return undefined;
        return state.event;
    }
}

// actions
const actions = {
    async getEvents({ commit }, params) {
        const events = await fetchData('/eventlatest', params, null, 2)
            .then(function (response) {
                return response;
            });

        commit('setEvents', { data: events });
    },
    async getEvent({ commit }, params) {
        const event = await fetchData('/eventdata', params, null, 2)
            .then(function (response) {
                return response;
            });

        commit('setEvent', { data: event });
    }
}

// mutations
const mutations = {
    setEvents(state, params) {
        // Set data
        state.events = params.data;
    },
    setEvent(state, params) {
        // Set data
        state.event = params.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
    <footer class="site-footer">
        <content-caddy-footer container-tag="nav" :content="footerFAQs" />
        <div class="site-footer-inner">
            <content-caddy-footer :content="footerCaddy" class="grid container" />
            <div class="container flex logocopy">
                <div class="footer-logo"><LogoButton /></div>
                <nav class="copy-links flex">
                    <router-link to="/terms-conditions" class="flex"><span>Terms &amp; Conditions</span></router-link>
                    <router-link to="/cookie-policy" class="flex"><span>Cookie policy</span></router-link>
                    <router-link to="/privacy-policy" class="flex"><span>Privacy policy</span></router-link>
                    <span>&copy; Fonmon Castle {{ year }}</span>
                </nav>
            </div>
        </div>
    </footer>
</template>

<script lang="js">
    import { mapGetters } from 'vuex'
    import LogoButton from './LogoButton.vue'
    import ContentCaddyFooter from '../content/footer/ContentCaddy.vue'

    export default {
        name: "the-footer",
        mounted() {
            // Get the main links
            this.$store.dispatch('content/getCaddy', { id: 3711, name: "footerCaddy" });
        },
        components: {
            LogoButton,
            ContentCaddyFooter
        },
        data() {
            return {
                year: new Date().getFullYear()
            };
        },
        computed: {
            ...mapGetters({ caddyByName: 'content/caddyByName' }),
            footerFAQs() {
                if (!this.caddyByName('footerCaddy')) return null;
                // This extracts the first content item from the footer caddy. This will be the FAQs
                const footFAQs = this.caddyByName('footerCaddy').content.shift();
                return footFAQs;
            },
            footerCaddy() {
                if (!this.caddyByName('footerCaddy')) return null;
                // The above is called first so at this point the footerCaddy will no longer contain the FAQs as shift will have removed them.
                return this.caddyByName('footerCaddy');
                //return this.caddyByName('footerCaddy');
            }
        }
    };
</script>

<style>
    .site-footer .site-footer-inner {
        background: var(--green5);
        background-size: cover;
        color: #fff;
        text-align: center;
    }

    .site-footer .container {
        position: relative;
        z-index: 2;
        padding: 0.8125rem 1.5rem 1.0625rem 1.5rem;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

        .site-footer .footer-logo {
            margin: 0 auto 1.875em auto;
            display: inline-block;
        }

            .site-footer .footer-logo a.logo-button {
                justify-content: center;
            }

    .site-footer .footer-logo a.logo-button picture.logo-image {
        flex-basis: 3em;
    }

    .site-footer .container h1 {
        font-size: var(--font-size-4);
        font-weight: 700;
    }

    .site-footer .copy-links {
        font-size: var(--font-size-0);
        font-weight: 300;
        align-items: center;
        justify-content: center;
    }
        .site-footer .copy-links > * {
            flex-basis: auto;
            display: inline-block;
            padding: 0 0.25em;
        }

        .site-footer .copy-links a {
            border-right: solid 1px #fff;
            color: #fff;
            text-decoration: none;
        }
        .site-footer .copy-links a:hover {
            text-decoration: underline;
        }

    @media screen and (min-width: 600px) {
        .site-footer .footer-logo {
            flex-basis: auto;
        }
            .site-footer .footer-logo a.logo-button {
                justify-content: flex-start;
            }
    }
    
    @media screen and (min-width: 800px) {
        .site-footer .container.flex {
            justify-content: flex-start;
            align-items: center;
        }

            .site-footer .container.flex .footer-logo {
                margin: 0;
            }

        .site-footer .logocopy > h2 {
            font-size: var(--font-size-6);
            line-height: 2.3125rem;
            flex-basis: auto;
            margin-bottom: 0;
            margin-left: 3rem;
        }

        .site-footer .container.flex .copy-links {
            justify-content: flex-end;
            margin-top: 1rem;
        }
    }

    /* Content Caddies */

    .site-footer .grid {
        padding: 3.75em 0 2.25em 0;
    }

    @media screen and (min-width: 800px) {
        .site-footer .grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr auto;
            column-gap: 1.25em;
            row-gap: 0.625em;
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }

</style>